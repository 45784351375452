
.fp-article {
  &-body {
    .fp-paragraph {
      @include font($text-md, $font-regular, normal);
      line-height: $line-height-md;
    }
  }
}

.fp-section {
  @media screen and (max-width: $mobile) {
    margin-bottom: 40px;
  }
}

.fp-blockquote__text::before {
  color: $primary-color;
}

.fp-blockquote__source::before {
  margin-right: 4px;
}

.fp-blockquote__source {
  margin-top: 16px;
}

.fp-blockquote__text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
