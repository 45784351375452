.fp-latest-article-list {
  > a {
    display: inline-block;
  }

  .fp-article {
    &__image {
      max-width: 100%;
      min-width: initial;

      @media screen and (max-width: $tablet) {
        max-width: initial;
        min-width: initial;
      }
    }

    &__content {
      @include space(margin, left, 0);
      @include space(padding, all, $spacer * 4);
      background: $color-white;
      bottom: 80px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
      float: right;
      margin-bottom: -80px;
      min-height: 100px;
      position: relative;
      width: calc(100% - 25px);

      @media screen and (max-width: $tablet) {
        @include space(margin, left, 10%);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        bottom: 60px;
        margin-bottom: -60px;
        //@include space(margin, left, 32px);
        width: 100%;
      }
    }

    &__catchline {
      @include space(padding, top, 0);
      @media screen and (max-width: $mobile) {
        @include space(padding, bottom, 0);
      }
    }

    &__title {
      @include space(padding, top, 0);
      color: $color-text;
    }
    // No main key visual
    &__no-img .fp-article {
      &__content {
        bottom: 0;
        float: initial;
        margin-bottom: 0;
        position: relative;
        @media screen and (max-width: $tablet) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
        }
        @media screen and (max-width: $mobile) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
          bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  // No main key visual
  .fp-article__no-img .fp-article {
    &__content {
      @include space(margin, left, 0);
      @include space(padding, all, $spacer * 6);
      bottom: 0;
      float: initial;
      margin-bottom: 0;
      position: relative;
      @media screen and (max-width: $tablet) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
        bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
// If article doesn't have image
.fp-article_teaser.fp-latest-article-list.fp-article__no-img {
  .fp-article__content {
    bottom: 0;
    margin: 0;
  }
}
