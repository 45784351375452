.fp-top-article-top-teaser {
  .fp-article {
    &__content {
      //      @include space(margin, left, 180px);
      @include space(padding, all, $spacer * 6);
      background: $color-white;
      bottom: 80px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
      float: right;
      margin-bottom: -80px;
      position: relative;
      width: calc(100% - 25px);

      @media screen and (max-width: 750px) {
        min-height: 100px;
        padding: 16px;
        width: 100%;

        .fp-article__catchline {
          padding: 0;
        }
      }

      @media screen and (max-width: $tablet) {
        @include space(margin, left, 10%);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        @include space(margin, left, 32px);
        bottom: 60px;
        margin-bottom: -60px;
      }
    }

    &__catchline {
      @include space(padding, top, 0);
      @media screen and (max-width: $mobile) {
        @include space(padding, bottom, 0);
      }
    }

    &__title {
      @include space(padding, top, $spacer * 2);
      color: $color-text;
    }
    // No main key visual
    &__no-img .fp-article {
      &__content {
        bottom: 0;
        float: initial;
        margin-bottom: 0;
        position: relative;
        @media screen and (max-width: $tablet) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
        }
        @media screen and (max-width: $mobile) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
          bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  // No main key visual
  .fp-article__no-img .fp-article {
    &__content {
      @include space(margin, left, 0);
      @include space(padding, all, $spacer * 6);
      bottom: 0;
      float: initial;
      margin-bottom: 0;
      position: relative;
      @media screen and (max-width: $tablet) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
        bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}

.fp-top-article-bottom-teasers {
  .fp-article {
    margin-bottom: -40px;

    &__content {
      @include space(margin, left, 24px);
      @include space(padding, horizontal, $spacer * 4);
      background: $color-white;
      bottom: 40px;
      position: relative;

      @media screen and (max-width: $mobile) {
        @include space(margin, left, 16px);
      }
      @media screen and (max-width: 750px) {
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
        min-height: 100px;
        padding: 16px;

        .fp-article__catchline {
          padding: 0;
        }
      }
    }

    // No main key visual
    &__no-img {
      margin-bottom: 0;

      .fp-article {
        &__content {
          @include space(margin, left, 0);
          background: $color-white;
          bottom: 0;
          position: relative;
          @media screen and (max-width: $mobile) {
            @include space(margin, left, 0);

          }
        }
      }
    }

    &__title {
      @include space(padding, top, $spacer * 2);
    }
  }
}


section.fp-top-article-top-teaser {
  display: flex;
  flex-direction: column;
}



.fp-related-articles .secondary-teaser {
  @media screen and (max-width: $mobile) {
    border-bottom: 1px solid #b6bfc6;
    padding-bottom: 16px;
    padding-top: 16px;

    &:first-child {
      padding-top: 0;
    }
  }
}
