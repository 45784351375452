@import '../default/variables';


// Newsletter logo variable
$publication-newsletter-logo: '/design/source/images/rbl-logo.svg';

// override any variable below

// Typography Variables
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Poppins', sans-serif;


/* set base values */
$text-base-size: 16px;
$line-height-base: 22px;

// Font Size
$text-xs: 12px;
$text-sm: 14px;
$text-md: 18px;
$text-lg: 20px;
$text-xl: 22px;
$text-xxl: 24px;
$text-xxxl: 26px;


// Line Height
$line-height-xs: 18px;
$line-height-sm: 20px;
$line-height-md: 24px;
$line-height-lg: 27px;
$line-height-xl: 30px;
$line-height-xxl: 33px;
$line-height-xxxl: 36px;

// Font Weight
$weight-thin: 100;
$weight-extraLight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semiBold: 600;
$weight-bold: 700;
$weight-extraBold: 800;
$weight-black: 900;

// Color Variables
$red: #c03;
$orange: #e5732e;
$light-blue: #4aa5d6;
$dark-blue: #0c2940;

$color-primary: #06c;
$color-secondary: #16417c;
$color-primary-light: $orange;
$color-primary-light-2: $light-blue;
$color-text: #2a2828;

$color-primary--50: #b6bfc6;
$color-primary--30: #b6bfc6;
$color-text--80: #556979;
$color-borders: $color-primary--30;
$color-white: #fff;

$publication-newsletter-logo: '/design/source/images/sao-logo.svg';

