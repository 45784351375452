// Internal Teaser Component rb-live style
.normal {
  .fp-internal-teaser {
    border: 0;
    max-width: 600px;
    padding: 0;
    position: relative;

    &_header {
      display: none;
    }

    header {
      @include space(padding, all, $spacer * 4);
      background-color: rgba(18, 27, 49, 0.9);
      bottom: 0;
      position: absolute;
    }

    &_category {
      font-size: $text-md;

      line-height: $line-height-md;
      padding-top: 0;
      text-align: left;

    }

    &_title {
      color: white;
      font-size: $text-lg;
      line-height: $line-height-lg;
      text-align: left;
    }
  }

  a:hover {
    .fp-internal-teaser_title {
      text-decoration: underline;
    }
  }
}
